import styled from 'styled-components';

import { Theme } from '../../../theme';
import { Input } from '../Input';

export const StyledIcon = styled.div<Theme>`
  color: ${p => p.theme?.primaryColor};
`;

export const StyledInput = styled(Input)`
  padding-left: 40px;
  line-height: 38px;

  &::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }
`;

export const StyledHelper = styled.div`
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
`;
