import React, { FC, useCallback, useEffect, useState } from 'react';
import { usePosition } from 'use-position';
import { LatLngLiteral } from 'leaflet';
import { useFirebase, useFirestore, useFirestoreConnect, isLoaded } from 'react-redux-firebase';
import { connect, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useParams } from 'react-router-dom';
// import firebase from 'firebase';
// import GeoPoint = firebase.firestore.GeoPoint;
// import Timestamp = firebase.firestore.GeoPoint;

import { RootState } from '../../store/reducers';
import { userIdSelector } from '../../store/firebase/selectors';
import { Form, FormFields } from './Form';
import { routes } from '../../routes';
import { Field } from '../../models';

type Props = {} & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {
  push,
};

const COLLECTION_NAME = 'fields';

export const FormContainerPure: FC<Props> = ({ push }) => {
  const { latitude, longitude } = usePosition(false);
  const [position, setPosition] = useState<LatLngLiteral | undefined>(undefined);
  const userId = useSelector(userIdSelector);
  const firebase = useFirebase();
  const firestore = useFirestore();
  const { docId } = useParams();

  useFirestoreConnect(() => [{ collection: COLLECTION_NAME, doc: docId }]);

  // @ts-ignore
  const data = useSelector(({ firestore: { data } }) => data[COLLECTION_NAME]?.[docId]);

  const logout = () => firebase.logout();

  useEffect(() => {
    if (latitude && longitude) {
      setPosition({ lat: latitude, lng: longitude });
    }
  }, [latitude, longitude]);

  const handleSubmit = useCallback(
    (data?: FormFields) => {
      if (data) {
        const files: Field['files'] = [];
        data.files.forEach(({ url, name }) => {
          if (url) files.push({ url, name });
        });

        const doc: Field = {
          files,
          createdBy: userId,
          field: data.field,
          comment: data?.comment ?? '',
          datetime: data?.datetime ?? '',
        };

        if (data?.location) doc.location = data.location;

        if (data?.whereSell) doc.whereSell = data.whereSell;
        if (data?.firstSymptoms) doc.firstSymptoms = data.firstSymptoms;
        if (data?.percentOfInfestation) doc.percentOfInfestation = data.percentOfInfestation;
        if (data?.drought) doc.drought = data.drought;

        try {
          console.info('saving', doc);
          const collection = firestore.collection(COLLECTION_NAME);
          if (docId) {
            // update
            collection
              .doc(docId)
              .set(doc)
              .then(() => push(routes.fields));
          } else {
            // create
            collection.add(doc).then(() => push(routes.fields));
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    [userId, docId, firestore, push],
  );

  const handleBack = useCallback(() => {
    push(routes.fields);
  }, [push]);

  if (docId && !isLoaded(data)) {
    return <div>Loading...</div>;
  }

  return <Form position={position} data={data} onSubmit={handleSubmit} userId={userId} onBack={handleBack} />;
};

export const FormContainer = connect(mapStateToProps, mapDispatchToProps)(FormContainerPure);
