import { StateType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { PersistConfig } from 'redux-persist/es/types';
import { FirebaseReducer, firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

import { history } from './history';
import { runtimeReducer } from './runtime/reducers';

interface UserProfile {
  email: string;
}

// export interface TodoValue {
//   text: string;
//   done: boolean;
// }

// create schema for the DB
interface DBSchema {
  // todos: TodoValue;
  [name: string]: any;
}

type FirebaseState = {
  firebase: FirebaseReducer.Reducer<UserProfile, DBSchema>;
};

const persistConfig: PersistConfig<any> = {
  storage,
  key: 'root',
  blacklist: ['firebase'],
  version: 0,
};

export const rootReducer = persistReducer(
  persistConfig,
  combineReducers({
    router: connectRouter(history),
    runtime: runtimeReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
  }),
);

export type RootState = StateType<typeof rootReducer>;
