import React from 'react';
import { Select as UISelect } from '@dvhb/ui';

import { useTheme } from '../../theme';
import { StyledControl, StyledSelectContainer } from './styles';

const Control = ({ cx, ...props }: any) => {
  const theme = useTheme();
  return <StyledControl {...props} {...props.innerProps} theme={theme} />;
};

const SelectContainer = ({ cx, ...props }: any) => <StyledSelectContainer {...props} {...props.innerProps} />;

// TODO: select props not working!!!
export const Select = ({ ...rest }: any) => {
  return (
    <UISelect
      components={{
        SelectContainer,
        Control,
      }}
      {...rest}
    />
  );
};
