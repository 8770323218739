import React, { FC } from 'react';
import styled from 'styled-components';
import { Modal as UIModal, ModalProps as UIModalProps } from '@dvhb/ui';

import { StyledModalContent, StyledText } from './styles';

const ModalContent = (props: any) => <StyledModalContent {...props} />;
const CloseButton = () => <></>;

export type ModalProps = {} & UIModalProps;

const ModalPure: FC<ModalProps> = ({ className = 'ReactModal', components, isOpen, children, ...rest }) => {
  const contentWrapperClassName = `${className}__contentWrapper`;
  const overlayClassName = `${className}__overlay`;

  return (
    <UIModal
      components={{ ModalContent, CloseButton, ...components }}
      contentWrapperClassName={contentWrapperClassName}
      overlayClassName={overlayClassName}
      isOpen={isOpen}
      disableBodyScroll={false}
      {...rest}
    >
      <StyledText tag="div">{children}</StyledText>
    </UIModal>
  );
};

export const Modal = styled(ModalPure)`
  &__contentWrapper {
    margin: 0;
    padding: 0;
    display: flex;
    -webkit-overflow-scrolling: touch;
    overflow: auto;
  }
  &__overlay {
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
    overflow: auto;
  }
`;
