import { createSelector } from 'reselect';
import { RootState } from '../reducers';

const getFirebase = (state: RootState) => state.firebase;

export const isAuthenticatedSelector = createSelector(getFirebase, ({ auth }) => !auth.isEmpty && !!auth.uid);
export const isAnonymousSelector = createSelector(getFirebase, ({ auth }) => !!auth.isAnonymous);
export const userIdSelector = createSelector(getFirebase, ({ auth }) => auth.uid);
export const isAuthenticatingSelector = createSelector(
  getFirebase,
  ({ auth, isInitializing }) => !auth.isLoaded || isInitializing,
);
