import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';

import { SignIn } from './SignIn';
import { RootState } from '../../store/reducers';

type Props = {} & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {};

export const SignInContainerPure: FC<Props> = () => {
  return <SignIn />;
};

export const SignInContainer = connect(mapStateToProps, mapDispatchToProps)(SignInContainerPure);
