import React, { FC } from 'react';
import { Provider } from '@dvhb/ui';

import { Button, Text } from '../ui';

declare type Props = {
  children: React.ReactNode;
};
export const DvhbUiProvider: FC<Props> = ({ children }) => (
  <Provider
    components={{
      Button,
      Text,
    }}
  >
    {children}
  </Provider>
);
