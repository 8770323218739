import React from 'react';
import { Field as UIField, FieldProps as UIFieldProps, Spacer } from '@dvhb/ui';

import { Text } from '../Text';

export type FieldProps = {} & UIFieldProps;

const Label = (props: any) => <Text size="h2" {...props} />;

export const Field = ({ ...rest }: FieldProps) => {
  return (
    <>
      <UIField components={{ Label }} {...rest} />
      <Spacer />
    </>
  );
};
