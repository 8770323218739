import styled from 'styled-components';

export const StyledLayout = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  flex: auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

export const StyledLayoutContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

export const StyledLayoutContent = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  max-width: 600px;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
`;
