import React, { useCallback, useState } from 'react';

// @ts-ignore
import { IMaskMixin } from 'react-imask';
import { useTheme } from '../../theme';
import { StyledInput } from './styles';

const PercentInputPure = IMaskMixin(({ inputRef, ...props }: any) => {
  const theme = useTheme();
  return <StyledInput theme={theme} {...props} ref={inputRef} />;
});

export const PercentInput = ({ value, ...rest }: any) => {
  return (
    <PercentInputPure
      mask="num%"
      blocks={{
        num: {
          mask: Number,
          min: 0,
          max: 100,
        },
      }}
      value={value}
      lazy={!value}
      {...rest}
    />
  );
};
