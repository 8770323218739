import React, { createRef, FC, useCallback, useEffect, useState } from 'react';
import { Map, TileLayer, LayersControl, Marker } from 'react-leaflet';
import L, { LatLng, LatLngExpression, LatLngTuple } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './LeafletMap.css';
import PinIcon from '../../ui/Icon/src/Pin.svg';
import MapKitLayer from './plugins/MapKitLayer';

export type LeafletMapProps = {
  lat: number;
  lng: number;
  zoom?: number;
  draggable?: boolean;
  onChangePosition?: (position: LatLng) => void;
};

const { BaseLayer } = LayersControl;

export const LeafletMap: FC<LeafletMapProps> = ({ lat, lng, draggable, zoom = 18, onChangePosition }) => {
  const refMarker = createRef<Marker>();
  const [markerPosition, setMarkerPosition] = useState<LatLngExpression>([lat, lng]);
  const position: LatLngTuple = [lat, lng];

  useEffect(() => {
    setMarkerPosition([lat, lng]);
  }, [lat, lng]);

  L.Marker.prototype.options.icon = L.icon({
    iconUrl: PinIcon,
    iconSize: [28, 49],
    iconAnchor: [14, 49],
  });

  const updatePosition = useCallback(() => {
    const marker = refMarker.current;
    if (marker != null) {
      const point = marker.leafletElement.getLatLng();
      if (point) {
        setMarkerPosition(point);
        onChangePosition?.(point);
      }
    }
  }, [refMarker]);

  const mapkitAuthorizationCallback = (done: any) => done(process.env.REACT_APP_MAPKIT_TOKEN);
  return (
    <Map center={position} zoom={zoom} style={{ flex: 'auto' }}>
      <LayersControl position="topright">
        <BaseLayer checked name="MapKit" key="MapKit">
          <MapKitLayer authorizationCallback={mapkitAuthorizationCallback} url="" />
        </BaseLayer>
        <BaseLayer name="OpenStreetMap">
          <TileLayer
            attribution='&amp;copy <a href="//osm.org/copyright">OpenStreetMap</a> contributors'
            url="//{s}.tile.osm.org/{z}/{x}/{y}.png"
            maxZoom={21}
          />
        </BaseLayer>
      </LayersControl>

      <Marker position={markerPosition} ref={refMarker} ondragend={updatePosition} draggable={draggable} />
    </Map>
  );
};
