import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { RootState } from '../../store/reducers';
import { isAuthenticatedSelector, isAuthenticatingSelector } from '../../store/firebase/selectors';
import { routes } from '../../routes';

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: isAuthenticatedSelector(state),
  isAuthenticating: isAuthenticatingSelector(state),
});

const mapDispatchToProps = () => ({});

type Props = {
  private?: boolean;
} & RouteProps &
  ReturnType<typeof mapStateToProps>;

const PrivateRoutePure = ({ isAuthenticated, isAuthenticating, private: isPrivate, children, ...props }: Props) => {
  if (isAuthenticating) return <></>;
  const renderRoute = () => (isPrivate && !isAuthenticated ? <Redirect to={routes.index} /> : children);
  return <Route {...props} render={renderRoute} />;
};

export const PrivateRoute = connect(mapStateToProps, mapDispatchToProps)(PrivateRoutePure);
