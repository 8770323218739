import React, { FC } from 'react';
import { Switch } from 'react-router-dom';

import { routes } from '../../routes';
import { history, store, persistor } from '../../store';
import { Provider } from '../Provider';
import { PrivateRoute as Route } from '../PrivateRoute';
import { WelcomeContainer, FormContainer, SignInContainer, FieldsContainer, ProfileContainer } from '../../pages';
import { DvhbUiProvider, GlobalStyle, ThemeProvider, themes } from '../../theme';

export const App: FC = () => {
  return (
    <DvhbUiProvider>
      <ThemeProvider theme={themes.themeBeetroot}>
        <Provider store={store} history={history} persistor={persistor}>
          <GlobalStyle />

          <Switch>
            <Route private path={routes.profile}>
              <ProfileContainer />
            </Route>
            <Route path={routes.signIn}>
              <SignInContainer />
            </Route>
            <Route path={routes.index} exact>
              <WelcomeContainer />
            </Route>
            <Route private path={routes.fields}>
              <FieldsContainer />
            </Route>
            <Route private path={routes.form.index}>
              <FormContainer />
            </Route>
          </Switch>
        </Provider>
      </ThemeProvider>
    </DvhbUiProvider>
  );
};
