import styled from 'styled-components';

import { Text } from '../Text';

export const StyledModalContent = styled.div`
  max-width: none;
  flex: auto;
  background: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
`;

export const StyledText = styled(Text)`
  display: flex;
  flex-direction: column;
  flex: auto;
`;
