import styled, { css } from 'styled-components';
import { Theme } from '../../theme';

export const StyledControl = styled('div')<Theme & any>`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;

  border-color: ${p =>
    (p.selectProps.error ? p.theme?.input?.borderColorError : p.theme?.input?.borderColor) || '#B2B6BE'};
  border-radius: ${p => p.theme?.input?.borderRadius || '4px'};
  min-height: ${p => p.theme?.input?.height || '40px'};
  background-color: ${p => p.theme?.input?.backgroundColor};
  font-family: ${p => p.theme?.fontFamily};
  font-size: ${p => p.theme?.input?.fontSize};

  ${p =>
    p.menuIsOpen &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}

  ${p =>
    p.size === 'sm' &&
    css`
      min-height: 28px;
      font-size: 14px;
    `}
`;

export const StyledSelectContainer = styled.div<any>`
  ${p =>
    p.selectProps.menuIsOpen &&
    css`
      transform: translate3d(0, 0, 0);
      position: relative;
      z-index: 10;
    `}
`;
