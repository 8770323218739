import React, { ReactNode, useCallback } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';

import firebase from '../../services/firebase';

type ProviderProps = {
  children: ReactNode;
  store: any;
  history: any;
  persistor: any;
};

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  // enableLogging: true,
};

export const Provider = ({ children, store, history, persistor }: ProviderProps) => {
  const onBeforeLift = useCallback(() => {}, []);
  const rrfProps = {
    firebase,
    createFirestoreInstance, // <- needed if using firestore
    config: rrfConfig,
    dispatch: store.dispatch,
  };
  return (
    <ReduxProvider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <PersistGate loading={null} persistor={persistor} onBeforeLift={onBeforeLift}>
          <ConnectedRouter history={history}>{children}</ConnectedRouter>
        </PersistGate>
      </ReactReduxFirebaseProvider>
    </ReduxProvider>
  );
};
