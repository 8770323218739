import React, { FC } from 'react';

import { Layout, FirebaseAuth } from '../../components';
import firebase from '../../services/firebase';

// Configure FirebaseUI.
const uiConfig = {
  signInSuccessUrl: '/',
  signInOptions: [
    // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: false,
      signInMethod: firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
    },
  ],
  tosUrl: 'https://www.myeasyfarm.com/cgu',
  privacyPolicyUrl: 'https://www.myeasyfarm.com/politique-de-confidentialite',
};

export type SignInProps = {};

export const SignIn: FC<SignInProps> = () => {
  return (
    <Layout>
      <FirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    </Layout>
  );
};
