import React, { ReactNode } from 'react';
import { Form, FormProps } from 'react-final-form';

import { Button } from '../ui';

export const onSubmit = (values: object) => {
  window.alert(preJson(values));
};

// @ts-ignore
export const preJson = (values?: object) => JSON.stringify(values || {}, 0, 2);

type FinalForm = {
  initialValues?: object;
  children?: ReactNode;
} & Pick<FormProps, 'validate'>;

export const FormDemo = ({ children, ...props }: FinalForm) => {
  return (
    <Form onSubmit={onSubmit} {...props}>
      {({ handleSubmit, form, invalid, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          {children}
          <Button htmlType="submit" disabled={invalid || pristine}>
            Submit
          </Button>
          <Button htmlType="button" onClick={form.reset} disabled={submitting || pristine}>
            Reset
          </Button>
          <pre>{preJson(values)}</pre>
        </form>
      )}
    </Form>
  );
};
