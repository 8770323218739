import { createTheming, ThemingType } from '@callstack/react-theme-provider';
import { createGlobalStyle } from 'styled-components';

import { Theme } from './types';
import { themeBeetroot } from './themeBeetroot';

export * from './DvhbUiProvider';
export * from './types';

export const themes: { [key: string]: Theme } = {
  themeBeetroot,
};

export const { ThemeProvider, withTheme, useTheme }: ThemingType<Theme> = createTheming(themes.themeBeetroot);

export const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    height: 100%;
  }
  
  body{
    color: #060608;
    background-color: #dbdbdb;
    font-size: 14px;
    line-height: ${20 / 14};
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
  }
  
  #root{
    display: flex;
    flex-direction: column;
    flex: auto;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  img {
    max-width: 100%;
    display: block;
  }
`;
