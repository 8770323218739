import * as React from 'react';

const SvgFacebook = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.407.593 24 1.324 24h11.504v-9.281H9.703v-3.633h3.125V8.412c0-3.099 1.895-4.787 4.659-4.787.931-.002 1.862.045 2.789.14v3.24h-1.904c-1.506 0-1.8.712-1.8 1.763v2.313h3.6l-.467 3.633h-3.153V24h6.124c.731 0 1.324-.593 1.324-1.324V1.324C24 .593 23.407 0 22.676 0z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.551 24v-9.281h3.133l.467-3.633h-3.6V8.771c0-1.05.294-1.762 1.8-1.762h1.925V3.766a26.562 26.562 0 00-2.794-.141c-2.768 0-4.655 1.688-4.655 4.787v2.673H9.703v3.634h3.124V24h3.724z"
      fill="#4267B2"
    />
  </svg>
);

export default SvgFacebook;
