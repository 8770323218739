import * as React from 'react';

const SvgTwitter = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16z"
      fill="#1DA1F2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.072 24.44c7.096 0 10.976-5.88 10.976-10.976 0-.168 0-.336-.008-.496a7.903 7.903 0 001.928-2 7.83 7.83 0 01-2.216.608 3.855 3.855 0 001.696-2.136c-.744.44-1.568.76-2.448.936a3.839 3.839 0 00-2.816-1.216 3.858 3.858 0 00-3.856 3.856c0 .304.032.6.104.88A10.944 10.944 0 018.48 9.864a3.87 3.87 0 00-.52 1.936c0 1.336.68 2.52 1.72 3.208a3.79 3.79 0 01-1.744-.48v.048a3.862 3.862 0 003.096 3.784 3.846 3.846 0 01-1.744.064 3.852 3.852 0 003.6 2.68 7.755 7.755 0 01-5.712 1.592 10.748 10.748 0 005.896 1.744z"
      fill="#fff"
    />
  </svg>
);

export default SvgTwitter;
