import { Theme } from './types';

export const themeBeetroot: Theme = {
  primaryColor: '#8AC942',
  secondaryColor: '#15171B',
  errorColor: '#E5174B',
  color: '#060608',
  fontSize: '14px',
  fontFamily: 'Open Sans, sans-serif',

  button: {
    color: '#fff',
    fontSize: '14px',
    fontWeight: '700',
    borderRadius: '4px',
    height: '40px',
    minWidth: '320px',

    secondary: {
      backgroundColor: '#fff',
      color: '#FA6464',
      borderColor: '#FA6464',
      borderWidth: '1px',
    },

    outline: {
      backgroundColor: '#fff',
      color: '#659E25',
      borderColor: '#8AC942',
      borderWidth: '1px',
    },
  },
  input: {
    height: '40px',
    backgroundColor: '#fff',
    borderColor: '#A8B1BF',
    borderRadius: '4px',
    fontSize: '14px',
  },
  checkbox: {
    borderColor: '#767A83',
  },
  text: {
    h1: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: '400',
    },
    h2: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 'bold',
    },
    sm: {
      fontSize: '12px',
      lineHeight: '16px',
    },
    lg: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
};
