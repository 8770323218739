import * as React from 'react';

const SvgBack = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.293 19.707a1 1 0 001.414-1.414L5.414 13H19a1 1 0 100-2H5.414l5.293-5.293a1 1 0 00-1.414-1.414l-7 7a1 1 0 000 1.414l7 7z"
      fill="#15171B"
    />
  </svg>
);

export default SvgBack;
