import React, { FC, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { Welcome } from './Welcome';
import { routes } from '../../routes';
import { RootState } from '../../store/reducers';
import { isAuthenticatedSelector } from '../../store/firebase/selectors';

type Props = {} & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: isAuthenticatedSelector(state),
});

const mapDispatchToProps = {
  push,
};

export const WelcomeContainerPure: FC<Props> = ({ push, isAuthenticated }) => {
  useEffect(() => {
    if (isAuthenticated) {
      push(routes.fields);
    }
  }, [isAuthenticated, push]);

  const handleSignIn = useCallback(() => {
    push(routes.signIn);
  }, [push]);

  return <Welcome goSignIn={handleSignIn} />;
};

export const WelcomeContainer = connect(mapStateToProps, mapDispatchToProps)(WelcomeContainerPure);
