import React, { FC } from 'react';

import { Field } from '../../models';
import { FieldsList, Layout } from '../../components';
import { Button, Spacer, Text } from '../../ui';

export type FieldsProps = {
  data?: Field[];
  onNew?: () => void;
};

export const Fields: FC<FieldsProps> = ({ data, onNew }) => {
  return (
    <Layout headerProps={{ title: 'Vos saisies', profileButton: true }}>
      {data?.length ? (
        <FieldsList data={data} />
      ) : (
        <Text>
          Veuillez ajouter une parcelle et des photos de betteraves. Vous pouvez ajouter autant de parcelles et attacher
          autant de photos que nécessaire.
        </Text>
      )}

      <Spacer margin="lg" />
      <Button onClick={onNew}>Ajouter une parcelle et des photos</Button>
    </Layout>
  );
};
