import React from 'react';

import { Layout } from '../Layout';
import { Text, Row, Col, Spacer, List, Button, Icon } from '../../ui';
import goodPhoto from './assets/goodPhoto.png';
import badPhoto from './assets/badPhoto.png';
import { StyledImage, StyledTextCaption, StyledImageIcon } from './styles';

const listItems = [
  'Les photos doivent être prises à environ 1m de hauteur et verticalement par rapport au sol, sans prendre les pieds.',
  'Les photos doivent être prises entre 10h et 16h pour que la lumière soit correcte.',
  'Si possible, les photos doivent être prises par temps nuageux. Si le soleil est présent, il doit être dans le dos de la personne qui prend la photo.',
];

export type InstructionProps = {
  onBackPress?: () => void;
};

export const Instruction = ({ onBackPress }: InstructionProps) => {
  return (
    <Layout headerProps={{ onBackClick: onBackPress }}>
      <Text size="h1" centered>
        Instructions pour la prise de photos
      </Text>
      <Spacer margin="lg" />
      <Row>
        <Col>
          <StyledImage>
            <img src={goodPhoto} alt="Bon exemple" />
            <StyledImageIcon>
              <Icon name="True" />
            </StyledImageIcon>
          </StyledImage>
          <Spacer margin="xxs" />
          <StyledTextCaption color="green">Bon exemple</StyledTextCaption>
          <Spacer margin="xs" />
          <Text color="grey">Tout est respecté</Text>
        </Col>
        <Col>
          <StyledImage>
            <img src={badPhoto} alt="Mauvais exempl" />
            <StyledImageIcon>
              <Icon name="False" />
            </StyledImageIcon>
          </StyledImage>
          <Spacer margin="xxs" />
          <StyledTextCaption color="red">Mauvais exemple</StyledTextCaption>
          <Spacer margin="xs" />
          <Text color="grey">Mauvais angle et mauvaise distance</Text>
        </Col>
      </Row>
      <Spacer margin="lg" />

      <List items={listItems} />
      <Spacer margin="lg" />
      <Button onClick={onBackPress}>Continuer</Button>
    </Layout>
  );
};
