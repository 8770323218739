import React, { FC, useCallback, useState } from 'react';
import { LatLngLiteral } from 'leaflet';
import { Form as FinalForm, Field as FinalField } from 'react-final-form';

import { DatePhotoField, Instruction, Layout, LocationField, PercentInput, UploaderFirebase } from '../../components';
import { Field, Input, Button, Spacer, Modal, Select, InputWithIcon, RadioGroup } from '../../ui';
import { preJson } from '../../utils/forms';
import { Field as FieldProps, StorageFile } from '../../models';
import { dateNow } from '../../utils/dates';

export type FormFields = {} & FieldProps;

type Errors = {
  [key in keyof FormFields]: any;
};

const validate = async (values: FormFields) => {
  const errors = {} as Errors;
  if (validateFiles(values.files)) errors.files = true;
  if (!values.field) errors.field = true;
  if (!values.datetime) errors.datetime = true;
  if (!values.location) errors.location = true;
  return errors;
};

const validateFiles = (files: StorageFile[]): boolean => {
  if (!files.length) return true;
  let isLoading = false;
  files.forEach(f => {
    if (f.isLoading) isLoading = true;
  });
  return isLoading;
};

const selectOptions = [
  { value: 'Tereos', label: 'Tereos' },
  { value: 'Saint Louis Sucre', label: 'Saint Louis Sucre' },
  { value: 'Cristal Union', label: 'Cristal Union' },
  { value: 'Lesaffre Frères SA', label: 'Lesaffre Frères SA' },
  { value: 'Ouvré fils SA', label: 'Ouvré fils SA' },
];

export type FormProps = {
  debug?: boolean;
  data?: FormFields;
  userId?: string;
  position?: LatLngLiteral;
  onSubmit: (data?: FormFields) => void;
  onBack?: () => void;
};

export const Form: FC<FormProps> = ({ userId, data, debug, onSubmit, onBack }) => {
  const [showHelp, setShowHelp] = useState<boolean>(false);
  const initialValues: FieldProps = data || {
    files: [],
    comment: '',
    datetime: dateNow(),
    field: '',
  };

  const handleHelpClick = useCallback(() => {
    setShowHelp(true);
  }, [setShowHelp]);

  const handleRequestClose = useCallback(() => {
    setShowHelp(false);
  }, []);

  return (
    <Layout headerProps={{ onBackClick: onBack, onHelpClick: handleHelpClick }}>
      <FinalForm onSubmit={onSubmit} initialValues={initialValues} validate={validate}>
        {({ handleSubmit, invalid, values, pristine }) => (
          <form onSubmit={handleSubmit}>
            <FinalField name="files">
              {({ input }) => <UploaderFirebase value={input.value} onChange={input.onChange} filesPath={userId} />}
            </FinalField>
            <Spacer margin="xs" />
            <Button type="asLink" onClick={handleHelpClick}>
              INSTRUCTIONS POUR LA PRISE DE PHOTOS
            </Button>
            <Spacer />
            <FinalField name="field">
              {({ input }) => (
                <Field label="Nom de la parcelle">
                  <Input value={input.value} onChange={input.onChange} placeholder="Entrer le nom de la parcelle" />
                </Field>
              )}
            </FinalField>
            <FinalField name="location">
              {({ input }) => (
                <Field label="Localisation de la parcelle">
                  <LocationField value={input.value} onChange={input.onChange} />
                </Field>
              )}
            </FinalField>
            <FinalField name="datetime">
              {({ input }) => (
                <Field label="Date et heure de la photo">
                  <DatePhotoField value={input.value} onChange={input.onChange} />
                </Field>
              )}
            </FinalField>
            <FinalField name="whereSell">
              {({ input }) => (
                <Field label="Betteraves achetées par">
                  <Select placeholder="" value={input.value} onChange={input.onChange} options={selectOptions} />
                </Field>
              )}
            </FinalField>
            <FinalField name="firstSymptoms">
              {({ input }) => (
                <Field label="Date estimée des premiers symptômes">
                  <InputWithIcon value={input.value} onChange={input.onChange} type="date" iconName="Calendar" />
                </Field>
              )}
            </FinalField>

            <FinalField name="percentOfInfestation">
              {({ input }) => (
                <Field label="% d’infestation de la jaunisse sur la parcelle">
                  <PercentInput value={input.value} onChange={input.onChange} />
                </Field>
              )}
            </FinalField>

            <FinalField name="drought">
              {({ input }) => (
                <Field label="Parcelle impactée par la sécheresse?">
                  <RadioGroup name="radioGroup" value={input.value} onChange={input.onChange}>
                    <RadioGroup.Item label="Oui" value="Oui" />
                    <RadioGroup.Item label="Non" value="Non" />
                  </RadioGroup>
                </Field>
              )}
            </FinalField>

            <Button block onClick={handleSubmit} disabled={invalid || pristine}>
              {data?.createdBy ? 'Mettre à jour' : 'Ajouter'}
            </Button>
            {debug && <pre>{preJson(values)}</pre>}
          </form>
        )}
      </FinalForm>
      <Modal isOpen={showHelp} onRequestClose={handleRequestClose}>
        <Instruction onBackPress={handleRequestClose} />
      </Modal>
    </Layout>
  );
};
