import React, { FC } from 'react';

import { StyledDateField } from './styles';
import { useTheme } from '../../theme';
import { InputWithIconProps } from '../Input';

export type DateFieldProps = {} & InputWithIconProps;

export const DateField: FC<DateFieldProps> = props => {
  const theme = useTheme();
  return (
    <StyledDateField
      {...props}
      // @ts-ignore
      iconName="calendar"
      theme={theme}
      type="date"
    />
  );
};
