import React, { FC, useCallback } from 'react';

import { Modal, Text, Button, Spacer } from '../../../ui';
import { Layout } from '../..';

export type ModalConfirmDeleteProps = {
  isOpen: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
};

export const ModalConfirmDelete: FC<ModalConfirmDeleteProps> = ({ isOpen, onConfirm, onCancel }) => {
  const handleCancel = useCallback(
    (e: React.MouseEvent) => {
      e && e.preventDefault();
      onCancel?.();
    },
    [onCancel],
  );
  const handleConfirm = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      onConfirm?.();
    },
    [onConfirm],
  );

  return (
    <Modal isOpen={isOpen} onRequestClose={handleCancel}>
      <Layout headerProps={{ onBackClick: handleCancel as () => void }}>
        <Text size="h2">Êtes-vous vraiment sûr de vouloir supprimer le compte? Pas de retour en arrière possible</Text>
        <Spacer />
        <Button type="secondary" onClick={handleConfirm}>
          Supprimer le compte
        </Button>
        <Spacer />
        <Button type="outline" onClick={handleCancel}>
          Annuler
        </Button>
      </Layout>
    </Modal>
  );
};
