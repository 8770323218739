import React, { FC } from 'react';

import { StyledTimeField } from './styles';
import { useTheme } from '../../theme';
import { InputWithIconProps } from '../Input';

export type TimeFieldProps = {} & InputWithIconProps;

export const TimeField: FC<TimeFieldProps> = props => {
  const theme = useTheme();
  return (
    <StyledTimeField
      {...props}
      // @ts-ignore
      iconName="clock"
      mask="99:99"
      placeholder="hh:mm"
      theme={theme}
      type="tel"
    />
  );
};
