import { compile } from 'path-to-regexp';

export const routes = {
  index: '/',
  signIn: '/signIn',
  fields: '/fields',
  profile: '/profile',
  form: {
    index: '/form/:docId?',
    step1: '/form/step1',
    step2: '/form/step2',
    complete: '/form/complete',
  },
};

type Routes = {
  form: {
    index: (data: { docId?: string }) => string;
  };
};

export const compiledRoutes = {} as Routes;

for (const [section, routesList] of Object.entries(routes)) {
  // @ts-ignore
  compiledRoutes[section] = {};

  for (const [key, route] of Object.entries(routesList)) {
    // @ts-ignore
    compiledRoutes[section][key] = compile(route);
  }
}
