import styled from 'styled-components';

export const StyledBadge = styled.span`
  box-sizing: border-box;
  min-height: 24px;
  padding: 4px 8px;
  align-items: center;
  display: inline-flex;
  border: 1px solid currentColor;
  border-radius: 4px;
  color: #414b5a;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 14px;
`;
