import * as React from 'react';

const SvgPin = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 28 49" fill="none" {...props}>
    <g filter="url(#Pin_svg__filter0_f)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 43c4.418 0 8-1.12 8-2.5S18.418 38 14 38s-8 1.12-8 2.5S9.582 43 14 43z"
        fill="#000"
        fillOpacity={0.5}
      />
    </g>
    <path fillRule="evenodd" clipRule="evenodd" d="M12 19h4v20l-2 2-2-2V19z" fill="#CCC" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 22c6.075 0 11-4.925 11-11S20.075 0 14 0 3 4.925 3 11s4.925 11 11 11z"
      fill="#fff"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M14 19a8 8 0 100-16 8 8 0 000 16z" fill="#8AC942" />
    <defs>
      <filter
        id="Pin_svg__filter0_f"
        x={0.563}
        y={32.563}
        width={26.873}
        height={15.873}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={2.718} result="effect1_foregroundBlur" />
      </filter>
    </defs>
  </svg>
);

export default SvgPin;
