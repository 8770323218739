import styled, { css } from 'styled-components';

export const StyledInput = styled.input`
  -webkit-appearance: none;
  border: 1px solid transparent;
  box-shadow: none;
  box-sizing: border-box;
  color: inherit;
  display: block;
  font-weight: normal;
  outline: none;
  padding: 0 12px;
  text-overflow: ellipsis;
  width: 100%;

  height: ${p => p.theme?.input?.height};
  background-color: ${p => p.theme?.input?.backgroundColor};
  border-color: ${p => p.theme?.input?.borderColor};
  font-size: ${p => p.theme?.input?.fontSize};
  border-radius: ${p => p.theme?.input?.borderRadius};
  font-family: ${p => p.theme?.fontFamily};

  &:focus {
    border-color: ${p => p.theme?.primaryColor};
  }

  ${p =>
    p.required &&
    css`
      && {
        border-color: ${p => p.theme?.errorColor};
      }
    `}
`;
