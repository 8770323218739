import React, { FC, useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import { DateField, TimeField, Row, Col } from '../../ui';
import { DATE_FORMAT, TIME_FORMAT, parseDateFromString, parseTimeFromString } from '../../utils/dates';

export type DatePhotoFieldProps = {
  value?: string;
  onChange?: (value?: string) => void;
};

export const DatePhotoField: FC<DatePhotoFieldProps> = ({ value, onChange }) => {
  const [date, setDate] = useState<string>(parseDateFromString(value));
  const [time, setTime] = useState<string>(parseTimeFromString(value));

  useEffect(() => {
    if (!isMasked(date) && !isMasked(time)) {
      const m = moment(`${date}T${time}`, `${DATE_FORMAT}T${TIME_FORMAT}`);
      if (m.isValid()) {
        const newValue = m.format();
        if (newValue !== value) {
          onChange?.(newValue);
        }
      }
    } else {
      if (value) {
        onChange?.(undefined);
      }
    }
  }, [date, time]);

  const handleChangeDate = useCallback(
    ({ target: { value: rawValue } }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setDate(rawValue);
    },
    [],
  );

  const handleChangeTime = useCallback(
    ({ target: { value: rawValue } }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setTime(rawValue);
    },
    [],
  );

  return (
    <Row>
      <Col>
        <DateField value={date} onChange={handleChangeDate} />
      </Col>
      <Col>
        <TimeField value={time} onChange={handleChangeTime} />
      </Col>
    </Row>
  );
};

const isMasked = (value: any) => {
  const reg = new RegExp(/_/g);
  return reg.test(value);
};
