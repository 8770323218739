import React, { FC, useCallback } from 'react';
import { useFirebase } from 'react-redux-firebase';
import { FileSelected, Uploader, UploaderProps } from '../Uploader';
import { timestamp } from '../../utils/dates';

export type UploaderFirebaseProps = {
  filesPath?: string;
} & UploaderProps;

const updateFiles = (files: FileSelected[], file: FileSelected): FileSelected[] => {
  return files.map(f => (f === file ? file : f));
};

export const UploaderFirebase: FC<UploaderFirebaseProps> = ({ filesPath = '', onChange, ...props }) => {
  const firebase = useFirebase();

  const handleChange = useCallback(
    (files: FileSelected[]) => {
      // @todo need remove uploaded files from storage

      // upload to firebase and get download url with private token
      if (files) {
        files.forEach(file => {
          if (!file.loaded && file.isLocal) {
            file.isLoading = true;
            onChange?.(updateFiles(files, file));

            firebase
              .uploadFile(filesPath, file, filesPath, { name: `${timestamp()}-${file.name}` })
              .then(({ uploadTaskSnapshot }) => {
                file.isLoading = false;
                onChange?.(updateFiles(files, file));

                uploadTaskSnapshot.ref.getDownloadURL().then(url => {
                  file.url = url;
                  file.loaded = true;
                  file.isLocal = false;
                  onChange?.(updateFiles(files, file));
                });
              });
          }
        });
        onChange?.(files);
      }
    },
    [firebase, onChange, filesPath],
  );
  return <Uploader {...props} onChange={handleChange} />;
};
