import React, { FC, useCallback } from 'react';
import cn from 'classnames';
import { useDropzone } from 'react-dropzone';

import styles from './styles.module.scss';
import { Button, Spacer, Row, Icon } from '../../ui';

export type UploaderProps = {
  value?: FileSelected[];
  disabled?: boolean;
  onChange?: (files: FileSelected[]) => void;
};

export type FileSelected = {
  url?: string;
  isLoading?: boolean;
  isLocal?: boolean;
  loaded?: boolean;
  progress?: number;
} & File;

export const Uploader: FC<UploaderProps> = ({ value, onChange }) => {
  const files = Array.isArray(value) ? value : [];
  let isLoading = false;

  files.forEach(f => {
    if (f.isLoading) isLoading = true;
  });

  const handleFileDelete = useCallback(
    (file: FileSelected) => () => {
      const filteredFiles = files.filter(f => f.name !== file.name);
      onChange?.(filteredFiles);
    },
    [files, onChange],
  );

  const handleDrop = useCallback(
    (newFiles: File[]) => {
      newFiles.forEach(file => {
        files.push(
          Object.assign(file, {
            isLocal: true,
            url: URL.createObjectURL(file),
          }),
        );
      });

      onChange?.([...files]);
    },
    [onChange, files],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    onDrop: handleDrop,
  });

  // useEffect(
  //   () => () => {
  //     // Make sure to revoke the data uris to avoid memory leaks
  //     files.forEach(file => file.url && URL.revokeObjectURL(file.url));
  //   },
  //   [files],
  // );

  const thumbs = files.map((file, key) => (
    <div className={cn(styles.thumb)} key={file.name}>
      <div className={cn(styles.thumbInner)}>
        <img src={file.url} className={cn(styles.thumbImg)} alt={file.name} />

        {file.isLoading ? (
          <div className={styles.loading}>Loading</div>
        ) : (
          <Button
            className={cn(styles.thumbDelete)}
            type="unstyled"
            onClick={handleFileDelete(file)}
            disabled={file.isLoading}
          >
            <Icon name="Close" />
          </Button>
        )}
      </div>
    </div>
  ));

  return (
    <div>
      {thumbs.length > 0 && (
        <div>
          <Row>{thumbs}</Row>
          <Spacer margin="xs" />
        </div>
      )}
      <div {...getRootProps()}>
        <input {...getInputProps()} disabled={isLoading} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <Button disabled={isLoading} type="outline" block>
            {files.length ? "Ajouter d'autres photos" : 'Ajouter des photos'}
          </Button>
        )}
      </div>
    </div>
  );
};
