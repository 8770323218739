import styled from 'styled-components';

export const StyledHeaderBar = styled.div`
  background: #fff;
  padding: 8px 16px;
  box-sizing: border-box;
  display: flex;
  flex: none;
  min-height: 56px;
  align-items: center;
`;

export const StyledLeftColumn = styled.div`
  display: flex;
`;

export const StyledRightColumn = styled.div`
  display: flex;
  margin-left: auto;
`;

export const StyledHeaderButtonIcon = styled.div`
  font-size: 36px;
  display: flex;
`;
