import React, { FC, useCallback, useEffect, useState } from 'react';
import { LatLngLiteral } from 'leaflet';
import { usePosition } from 'use-position';

import { LeafletMap } from '../LeafletMap';
import { Button, Modal, Spacer, Text } from '../../ui';
import { HeaderBar } from '../HeaderBar';
import { StyledButton } from './styles';

export type LocationFieldProps = {
  value?: LatLngLiteral;
  onChange?: (value: LatLngLiteral) => void;
};

const defaultPosition: LatLngLiteral = {
  lat: 48.864716,
  lng: 2.349014,
};

export const LocationField: FC<LocationFieldProps> = ({ value, onChange }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [newPosition, setNewPosition] = useState<LatLngLiteral | null>(null);
  // @ts-ignore
  const { latitude, longitude, error } = usePosition(true, {
    timeout: Infinity,
    maximumAge: 0,
    enableHighAccuracy: true,
  });

  useEffect(() => {
    if (latitude && longitude) {
      const newValue: LatLngLiteral = { lat: latitude, lng: longitude };
      if (!value) {
        setTimeout(() => onChange?.(newValue), 100);
      }
    }
  }, [value, latitude, longitude]);

  const handleChange = useCallback(
    (newValue: LatLngLiteral) => {
      setTimeout(() => setNewPosition?.(newValue));
    },
    [onChange, setNewPosition],
  );

  const handleSelectMap = useCallback(() => {
    setModalOpen(true);
  }, [onChange]);

  const handleRequestClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleSelectNewPosition = useCallback(() => {
    if (newPosition) {
      const { lat, lng } = newPosition;
      onChange?.({ lat, lng });
    } else {
      if (!value) onChange?.(defaultPosition);
    }
    handleRequestClose();
  }, [newPosition]);

  const accessDenied = error && !value;

  return (
    <>
      {accessDenied && <Text>Nous n’avons pas accès à votre localisation.</Text>}
      {value && <LeafletMap {...value} />}
      <Modal isOpen={modalOpen} onRequestClose={handleRequestClose}>
        <HeaderBar onBackClick={handleRequestClose} />
        <LeafletMap {...(value || defaultPosition)} draggable onChangePosition={handleChange} />
        <StyledButton>
          <Button block onClick={handleSelectNewPosition}>
            Confirmer la nouvelle localisation
          </Button>
        </StyledButton>
      </Modal>

      <Spacer margin="sm" />

      <Button type="asLink" onClick={handleSelectMap}>
        {accessDenied
          ? 'Veuillez choisir la localisation de votre parcelle sur la carte'
          : 'Choisir une autre localisation sur la carte'}
      </Button>
      <br />
    </>
  );
};
