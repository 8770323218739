import React, { FC } from 'react';
import { useComponents, Row, Col } from '@dvhb/ui';

import { Layout } from '../../components';
import { Spacer } from '../../ui';
import logo from './assets/logo.png';
import logoMef from './assets/logoMEF.png';
import partner_00 from './assets/partner_00.png';
import partner_01 from './assets/partner_01.png';
import partner_02 from './assets/partner_02.png';
import partner_03 from './assets/partner_03.png';
import partner_04 from './assets/partner_04.png';
import partner_05 from './assets/partner_05.png';
import partner_06 from './assets/partner_06.png';
import { StyledButton, StyledLogo, StyledLogoMef, StyledPoveredBy } from './styled';

export type WelcomeProps = {
  goSignIn?: () => void;
};

export const Welcome: FC<WelcomeProps> = ({ goSignIn }) => {
  const { Button, Text } = useComponents();

  return (
    <Layout>
      <StyledLogo>
        <img src={logo} alt="logo" />
      </StyledLogo>
      <Text centered size="h1">
        Recensement et cartographie de la qualité des parcelles de betteraves pour la campagne 2020
      </Text>
      <Spacer />

      <StyledButton>
        <Button block onClick={goSignIn}>
          Connectez-vous pour continuer
        </Button>
      </StyledButton>

      <Spacer margin="xlg" />

      <Row>
        <Col xs={3}>
          <img src={partner_00} alt="" />
        </Col>
        <Col xs={3}>
          <img src={partner_01} alt="" />
        </Col>
        <Col xs={3}>
          <img src={partner_02} alt="" />
        </Col>
        <Col xs={3}>
          <img src={partner_03} alt="" />
        </Col>
        <Col xs={4}>
          <img src={partner_04} alt="" />
        </Col>
        <Col xs={4}>
          <img src={partner_05} alt="" />
        </Col>
        <Col xs={4}>
          <img src={partner_06} alt="" />
        </Col>
      </Row>

      <StyledLogoMef>
        <StyledPoveredBy>powered by</StyledPoveredBy>
        <Spacer margin="xs" />
        <img src={logoMef} alt="logo Mef" />
      </StyledLogoMef>
    </Layout>
  );
};
