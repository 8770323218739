import styled from 'styled-components';

export const StyledLogo = styled.div`
  margin: 0 auto 8px;
  max-width: 200px;
`;

export const StyledLogoMef = styled.div`
  max-width: 100px;
  margin: 8px auto 0;
`;

export const StyledPoveredBy = styled.div`
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
`;

export const StyledButton = styled.div`
  flex: none;
`;
