import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledFieldList = styled.div`
  margin: 0 -20px;
  border-top: 1px solid #c6c9ce;
  border-bottom: 1px solid #c6c9ce;
`;

export const StyledFieldListItem = styled(Link)`
  padding: 12px 20px;
  display: block;
  text-decoration: none;

  & + & {
    border-top: 1px solid #c6c9ce;
  }
`;

export const StyledText = styled.div`
  color: #659e25;
`;
