import * as React from 'react';

const SvgClose = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.293 6.293a1 1 0 011.414 0l10 10a1 1 0 01-1.414 1.414l-10-10a1 1 0 010-1.414z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.707 6.293a1 1 0 010 1.414l-10 10a1 1 0 01-1.414-1.414l10-10a1 1 0 011.414 0z"
    />
  </svg>
);

export default SvgClose;
