import React from 'react';

import { compiledRoutes } from '../../routes';
import { Field } from '../../models';
import { StyledFieldList, StyledFieldListItem, StyledText } from './styles';
import { Aligner, Icon, Text, Spacer } from '../../ui';
import { Badge } from '../Badge';
import { useTheme } from '../../theme';

export type FieldListProps = {
  data?: Field[];
};

export const FieldsList = ({ data }: FieldListProps) => {
  const theme = useTheme();

  if (!data) return null;

  return (
    <StyledFieldList>
      {data.map((f, index) => (
        <StyledFieldListItem key={index} to={compiledRoutes.form.index({ docId: f.id })}>
          <Aligner>
            <Aligner.Left>
              <Text size="h2" tag="div">
                <StyledText theme={theme}>{f.field}</StyledText>
              </Text>
            </Aligner.Left>
            <Aligner.Right>
              <Aligner>
                <Badge text={`${f.files.length} photo(s)`} />
                <Spacer marginRight="lg" />
                <Text color="primaryColor">
                  <Icon name="ArrowRight" />
                </Text>
              </Aligner>
            </Aligner.Right>
          </Aligner>
        </StyledFieldListItem>
      ))}
    </StyledFieldList>
  );
};
