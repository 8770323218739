import styled, { css } from 'styled-components';

export const StyledImage = styled.div`
  max-width: 160px;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
`;

export const StyledTextCaption = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;

  ${p =>
    p.color === 'green' &&
    css`
      color: #659e25;
    `}

  ${p =>
    p.color === 'red' &&
    css`
      color: #fa6464;
    `}
`;

export const StyledImageIcon = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
`;
