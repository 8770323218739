import React, { useCallback } from 'react';

import { Text, Aligner, Button, Icon, Spacer } from '../../ui';
import { StyledHeaderBar, StyledLeftColumn, StyledRightColumn, StyledHeaderButtonIcon } from './styles';
import { Link } from 'react-router-dom';
import { routes } from '../../routes';

export type HeaderBarProps = {
  title?: string;
  onBackClick?: () => void;
  onCloseClick?: () => void;
  profileButton?: boolean;
  onHelpClick?: () => void;
};

export const HeaderBar = ({ title, onBackClick, onCloseClick, profileButton, onHelpClick }: HeaderBarProps) => {
  const onBackClickHandler = useCallback(() => {
    onBackClick && onBackClick();
  }, [onBackClick]);

  const onCloseClickHandler = useCallback(() => {
    onCloseClick && onCloseClick();
  }, [onCloseClick]);

  if (!title && !onBackClick && !profileButton) return null;

  return (
    <StyledHeaderBar>
      <StyledLeftColumn>
        {onBackClick ? (
          <Button type="unstyled" onClick={onBackClickHandler}>
            <Aligner>
              <Icon name="Back" />
              <Spacer marginRight="default" />
              <Text size="h2">Revenir en arrière</Text>
            </Aligner>
          </Button>
        ) : (
          <Text size="h2">{title}</Text>
        )}
      </StyledLeftColumn>
      <StyledRightColumn>
        {onCloseClick && (
          <Button type="unstyled" onClick={onCloseClickHandler}>
            <StyledHeaderButtonIcon>
              <Icon size="inherit" name="Close" />
            </StyledHeaderButtonIcon>
          </Button>
        )}
        {profileButton && (
          <Button type="unstyled" tag="div">
            <Link to={routes.profile}>
              <Text color="primaryColor">
                <StyledHeaderButtonIcon>
                  <Icon size="inherit" name="Profile" />
                </StyledHeaderButtonIcon>
              </Text>
            </Link>
          </Button>
        )}

        {onHelpClick && (
          <Button type="unstyled" tag="div" onClick={onHelpClick}>
            <Text size="h2" color="primaryColor">
              Aide
            </Text>
            <Spacer marginRight="xs" />
            <Text color="primaryColor">
              <StyledHeaderButtonIcon>
                <Icon size="inherit" name="Help" />
              </StyledHeaderButtonIcon>
            </Text>
          </Button>
        )}
      </StyledRightColumn>
    </StyledHeaderBar>
  );
};
