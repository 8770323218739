import React, { FC, useCallback } from 'react';
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase';
import { connect, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { RootState } from '../../store/reducers';
import { isAnonymousSelector, userIdSelector } from '../../store/firebase/selectors';
import { Fields } from './Fields';
import { compiledRoutes } from '../../routes';

type Props = {} & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({
  isAnonymous: isAnonymousSelector(state),
});

const mapDispatchToProps = {
  push,
};

const COLLECTION_NAME = 'fields';
export const FieldsContainerPure: FC<Props> = ({ push }) => {
  const userId = useSelector(userIdSelector);
  useFirestoreConnect(() => [{ collection: COLLECTION_NAME, where: ['createdBy', '==', userId] }]);

  const data = useSelector(
    // @ts-ignore
    ({ firestore }) => {
      const fields: any = [];

      if (typeof firestore?.composite?.[COLLECTION_NAME] === 'object') {
        const values = Object.values(firestore?.composite?.[COLLECTION_NAME]);
        const keys = Object.keys(firestore?.composite?.[COLLECTION_NAME]);

        values.map((v: any, i) => {
          fields.push({
            id: keys[i],
            ...v,
          });
        });
      }
      return fields;
    },
  );

  const handleNew = useCallback(() => {
    push(compiledRoutes.form.index({}));
  }, [push]);

  if (!isLoaded(data)) {
    return <div>Loading...</div>;
  }

  return <Fields data={data} onNew={handleNew} />;
};

export const FieldsContainer = connect(mapStateToProps, mapDispatchToProps)(FieldsContainerPure);
