import moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'HH:mm';

export const parseDateFromString = (value: any): string => {
  if (!value) return '';
  const m = moment(value);
  if (m.isValid()) return m.format(DATE_FORMAT);
  return '';
};

export const parseTimeFromString = (value: any): string => {
  if (!value) return '';
  const m = moment(value);
  if (m.isValid()) return m.format(TIME_FORMAT);
  return '';
};

export const dateNow = (): string => moment().format();
export const timestamp = (): number => moment().unix();

export { DATE_FORMAT, TIME_FORMAT };
