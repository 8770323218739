import React, { FC, useCallback } from 'react';
import { Field, InputProps } from '@dvhb/ui';

import { Input } from '../Input';
import { StyledIcon, StyledInput, StyledHelper } from './styles';
import { useTheme } from '../../../theme';
import { Icon, IconNamesType } from '../../Icon';

export type InputWithIconProps = {
  iconName?: IconNamesType;
  onIconClick?: () => void;
} & InputProps;

export const InputWithIcon: FC<InputWithIconProps> = ({ iconName, onIconClick, ...props }) => {
  const theme = useTheme();

  const handleOnClick = useCallback(() => onIconClick?.(), [onIconClick]);

  return (
    <Field style={{ position: 'relative', zIndex: 2 }} bottomMargin={false}>
      <StyledInput {...props} />
      {iconName && (
        <StyledHelper onClick={handleOnClick}>
          <StyledIcon theme={theme}>
            <Icon name={iconName} />
          </StyledIcon>
        </StyledHelper>
      )}
    </Field>
  );
};
