import React from 'react';

import { StyledBadge } from './styles';

export type BadgeProps = {
  text: string;
};

export const Badge = ({ text }: BadgeProps) => {
  return <StyledBadge>{text}</StyledBadge>;
};
