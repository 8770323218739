import React, { FC, useCallback, useState } from 'react';
import { Field as FinalField, Form as FinalForm } from 'react-final-form';

import { User } from '../../models';
import { Layout, ModalConfirmDelete } from '../../components';
import { Button, Input, Field, Spacer } from '../../ui';
import { StyledButtons } from './styles';

export type ProfileFields = {} & User;

type Errors = {
  [key in keyof ProfileFields]: any;
};

const validate = async (values: ProfileFields) => {
  const errors = {} as Errors;
  if (!values.firstName) errors.firstName = true;
  if (!values.lastName) errors.lastName = true;
  if (!values.email) errors.email = true;
  return errors;
};

export type ProfileProps = {
  data?: ProfileFields;
  onSubmit: (data?: ProfileFields) => void;
  onLogout?: () => void;
  onBack?: () => void;
  onDeleteAccount?: () => void;
};

export const Profile: FC<ProfileProps> = ({ data, onSubmit, onLogout, onBack, onDeleteAccount }) => {
  const [showModalConfirmDelete, setShowModalConfirmDelete] = useState<boolean>(false);
  const initialValues: User = data || {
    email: '',
    lastName: '',
    firstName: '',
  };

  const handleCancelDelete = useCallback(() => setShowModalConfirmDelete(false), []);
  const handleShowModalConfirmDelete = useCallback(() => setShowModalConfirmDelete(true), []);
  const handleConfirmDelete = useCallback(() => {
    handleCancelDelete();
    onDeleteAccount?.();
  }, [onDeleteAccount, handleCancelDelete]);

  return (
    <Layout headerProps={{ title: 'Profil', onCloseClick: onBack }}>
      <FinalForm onSubmit={onSubmit} initialValues={initialValues} validate={validate}>
        {({ handleSubmit, invalid, values, pristine }) => (
          <form onSubmit={handleSubmit}>
            <FinalField name="email">
              {({ input }) => (
                <Field label="Email">
                  <Input value={input.value} onChange={input.onChange} placeholder="Myemail@gmail.com" disabled />
                </Field>
              )}
            </FinalField>

            <FinalField name="firstName">
              {({ input }) => (
                <Field label="Prénom">
                  <Input value={input.value} onChange={input.onChange} placeholder="Entrer votre prénom" />
                </Field>
              )}
            </FinalField>

            <FinalField name="lastName">
              {({ input }) => (
                <Field label="Nom">
                  <Input value={input.value} onChange={input.onChange} placeholder="Entrer votre nom" />
                </Field>
              )}
            </FinalField>
            <Spacer margin="lg" />
            <Button block onClick={handleSubmit} disabled={invalid || pristine}>
              Mettre à jour
            </Button>
          </form>
        )}
      </FinalForm>

      <Spacer margin="lg" />

      <StyledButtons>
        <Button block type="secondary" onClick={handleShowModalConfirmDelete}>
          Supprimer le compte
          <br />
          pas de retour en arrière possible
        </Button>
        <Spacer margin="lg" />
        <Button block type="outline" onClick={onLogout}>
          Se déconnecter
        </Button>
      </StyledButtons>

      <ModalConfirmDelete
        isOpen={showModalConfirmDelete}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />
    </Layout>
  );
};
