import React, { FC, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { Profile, ProfileFields } from './Profile';
import { RootState } from '../../store/reducers';
import { isAuthenticatedSelector } from '../../store/firebase/selectors';
import { useFirebase, isLoaded, ProfileType } from 'react-redux-firebase';
import { routes } from '../../routes';

type Props = {} & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: isAuthenticatedSelector(state),
});

const mapDispatchToProps = {
  push,
};

export const ProfileContainerPure: FC<Props> = ({ push, isAuthenticated }) => {
  const firebase = useFirebase();

  // Get profile from redux state
  const email = useSelector((state: RootState) => state.firebase.auth.email);
  const profile = useSelector((state: RootState) => state.firebase.profile);

  const handleLogout = useCallback(() => {
    firebase.logout();
  }, []);

  const handleBack = useCallback(() => {
    push(routes.fields);
  }, [push]);

  const handleDeleteAccount = useCallback(() => {
    firebase
      .auth()
      .currentUser?.delete()
      .catch(error => {
        if (error.code === 'auth/requires-recent-login') {
          // The user's credential is too old. She needs to sign in again.
          firebase
            .auth()
            .signOut()
            .then(() => {
              // The timeout allows the message to be displayed after the UI has
              // changed to the signed out state.
              setTimeout(() => {
                alert('Please sign in again to delete your account.');
              }, 1);
            });
        }
      });
  }, [firebase]);

  const handleSubmit = useCallback(
    (data?: ProfileFields) => {
      const profile = data as ProfileType;

      firebase.updateProfile(profile).then(() => {});
    },
    [profile, email],
  );

  if (!isLoaded(profile) || !isLoaded(email)) {
    return <>Loading...</>;
  }

  const data = {
    email,
    ...profile,
  };

  return (
    <Profile
      data={data}
      onLogout={handleLogout}
      onSubmit={handleSubmit}
      onBack={handleBack}
      onDeleteAccount={handleDeleteAccount}
    />
  );
};

export const ProfileContainer = connect(mapStateToProps, mapDispatchToProps)(ProfileContainerPure);
