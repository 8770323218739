import { GridLayer, withLeaflet } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet.mapkitmutant';

type Props = {
  type?: 'default' | 'satellite' | 'hybrid';
  authorizationCallback: Function;
  language: string;
};

// @ts-ignore
class MapKitLayer extends GridLayer<Props> {
  static defaultProps = {
    type: 'hybrid',
  };

  createLeafletElement(props: Props) {
    // @ts-ignore
    return new L.MapkitMutant(props);
  }
}

// @ts-ignore
export default withLeaflet(MapKitLayer);
