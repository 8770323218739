import React, { FC, ReactNode } from 'react';

import { StyledLayout, StyledLayoutContent, StyledLayoutContentWrapper } from './styles';
import { Theme, useTheme } from '../../theme';
import { HeaderBar, HeaderBarProps } from '../HeaderBar';

export type LayoutProps = {
  theme?: Theme;
  children?: ReactNode | string;
  headerProps?: HeaderBarProps;
};

export const Layout: FC<LayoutProps> = ({ children, headerProps }) => {
  const theme = useTheme();
  return (
    <StyledLayout theme={theme}>
      <HeaderBar {...headerProps} />
      <StyledLayoutContentWrapper>
        <StyledLayoutContent>{children}</StyledLayoutContent>
      </StyledLayoutContentWrapper>
    </StyledLayout>
  );
};
