import React, { FC } from 'react';

import { List as UIList, ListProps } from '@dvhb/ui';
import { Aligner, Text, Spacer } from '../';
import { StyledText } from './styled';

const ListItem = ({ index, children, ...props }: any) => {
  return (
    <>
      {index > 0 && <Spacer />}
      <Aligner valign="top">
        <StyledText>{index + 1}.</StyledText>
        <Text>{children}</Text>
      </Aligner>
    </>
  );
};

export const List: FC<ListProps> = ({ ...rest }) => {
  return <UIList components={{ ListItem }} {...rest} />;
};
